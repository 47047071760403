import { httpClient } from "./../config/httpClient";

export const dataProvider = {
  getList: (resource, { filter, pagination, sort } ) => {
    const url = `/${resource}`;
    const myFilter = filter;
    const myPagination = { page: pagination.page, per_page: pagination?.perPage};
    const mySort = { sort_by: sort?.field, sort_direction: sort?.order.toLowerCase()};
    const params = { ...myFilter, ...myPagination, ...mySort};

    return httpClient.get(url, { params }).then(response => {
      return { data: response.data, total: parseInt(response.headers["x-total"])};
    });
  },

  getOne: (resource, params ) => {
    const url = `/${resource}/${params.id}`;

    return httpClient.get(url).then(response => {
      return { data: response.data };
    });
  },

  getManyReference: (resource, { filter, pagination, sort } ) => {
    const url = `/${resource}`;
    const myFilter = filter;
    const myPagination = { page: pagination.page, per_page: pagination?.perPage};
    const mySort = { sort_by: sort?.field, sort_direction: sort?.order.toLowerCase()};
    const params = { ...myFilter, ...myPagination, ...mySort};

    return httpClient.get(url, {  params }).then(response => {
      return { data: response.data, total: parseInt(response.headers["x-total"])};
    });
  },


  getMany: (resource, { filter, pagination, sort } ) => {
    const url = `/${resource}`;

    return httpClient.get(url, { }).then(response => {
      return { data: response.data, total: parseInt(response.headers["x-total"])};
    });
  },

  create: (resource, { data }) => {
    const url = `/${resource}`;
    if (resource === "employers") {
      const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = e => reject(e)
            reader.readAsDataURL(file.rawFile);
        });
      const logo = data.logo;
      return convertFileToBase64(logo)
        .then(logoBase64 => {
          return httpClient.post(url, { ...data, logo: { base64: logoBase64 } })
        })
        .then(response => { return {data: response.data}})
    } else if (resource === "posts" && data.cover) {
      const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = e => reject(e)
            reader.readAsDataURL(file.rawFile);
        });
      const cover = data.cover;
      return convertFileToBase64(cover)
        .then(coverBase64 => {
          return httpClient.post(url, { ...data, cover: { base64: coverBase64 } })
        })
        .then(response => { return {data: response.data}})
    } else {
      return httpClient.post(url, data).then( response => {
        return { data: response.data };
      });
    };
  },

  update: (resource, { data, id }) => {
    const url = `/${resource}/${id}`;
    if (resource === "employers" && data.logo) {
      const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = e => reject(e)
            reader.readAsDataURL(file.rawFile);
        });
      const logo = data.logo;
      return convertFileToBase64(logo)
        .then(logoBase64 => {
          return httpClient.put(url, { ...data, logo: { base64: logoBase64 } })
        })
        .then(response => { return {data: response.data}})
    } else if (resource === "posts" && data.cover) {
      const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = e => reject(e)
            reader.readAsDataURL(file.rawFile);
        });
      const cover = data.cover;
      return convertFileToBase64(cover)
        .then(coverBase64 => {
          return httpClient.put(url, { ...data, cover: { base64: coverBase64 } })
        })
        .then(response => { return {data: response.data}})
    } else {
      return httpClient.put(url, data).then( response => {
        return { data: response.data };
      });
    }
  },

  publish: (resource, data ) => {
    const url = `/${resource}/${data.id}/publish`;

    return httpClient.get(url).then(response => {
      return { data: response.data };
    });
  },

  reject: (resource, data ) => {
    const url = `/${resource}/${data.id}/reject`;

    return httpClient.get(url).then(response => {
      return { data: response.data };
    });
  },

  unpublish: (resource, data ) => {
    const url = `/${resource}/${data.id}/unpublish`;

    return httpClient.get(url).then(response => {
      return { data: response.data };
    });
  },

  bump: (resource, data ) => {
    const url = `/${resource}/${data.id}/bump`;

    return httpClient.get(url).then(response => {
      return { data: response.data };
    });
  },

  export: (resource) => {
    const url = `${resource}/export`;

    return httpClient.get(url).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var filename="employers_" + new Date().toJSON().slice(0,10) + ".csv";
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return { data: response.data };
    });
  }
};
