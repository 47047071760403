import * as React from "react";
import { Create, SimpleForm, TextInput, AutocompleteInput, SaveButton, Toolbar, ReferenceInput, required, useRecordContext, ImageField, ImageInput } from 'react-admin';
import {useForm} from 'react-final-form';
import { useRef } from "react"
import { Editor } from '@toast-ui/react-editor';

import '@toast-ui/editor/dist/toastui-editor.css';
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";


const MarkdownEditor = ({ source, field }) =>{
  const record = useRecordContext();
  const form = useForm();
  const editorRef = useRef(null);

  const handleValueChange = () => {
    const content = editorRef.current.editorInst.getMarkdown();
    form.change(field, content);
  }
  return (
    <>
    <Editor
      viever="true"
      theme="dark"
      onChange={handleValueChange}
      initialEditType="markdown"
      initialValue={record[field]}
      ref={editorRef}
    />
    </>
  )
}

const PostCreateToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const PostCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
        <TextInput source="slug" />
        <TextInput source="title" />
        <TextInput source="meta_title" />
        <TextInput source="meta_description" />
        <ReferenceInput reference="authors" perPage={30} validate={required()} source="author_id" >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={true}/>
        </ReferenceInput>
        <MarkdownEditor source="description" field="description" validate={required()} />
        <MarkdownEditor source="body" field="body" validate={required()} />
        <ImageInput source="cover" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>
  </Create>
);
