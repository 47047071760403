import React from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import { Admin, Resource } from "react-admin";
import { dataProvider, authProvider } from "./utils";
import { EmployerList, EmployerShow, EmployerEdit, EmployerCreate } from "./components/pages/Employer";
import { RecruiterList, RecruiterShow } from "./components/pages/Recruiter";
import { JobOfferToCheckList, JobOfferToCheckShow } from "./components/pages/JobOffersToCheck";
import { JobOfferList, JobOfferShow, JobOfferEdit, JobOfferCreate } from "./components/pages/JobOffers";
import { PostList, PostShow, PostEdit, PostCreate } from "./components/pages/Posts";
import { AuthorList, AuthorShow, AuthorEdit, AuthorCreate } from "./components/pages/Authors";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: teal,
    type: 'dark',
  }
});

const App = () => (
  <Admin
    theme={theme}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource name="employers" list={EmployerList} show={EmployerShow} edit={EmployerEdit} create={EmployerCreate} />
    <Resource name="recruiters" list={RecruiterList} show={RecruiterShow} />
    <Resource name="job_offers_to_check" list={JobOfferToCheckList} show={JobOfferToCheckShow} />
    <Resource name="job_offers" list={JobOfferList} show={JobOfferShow} edit={JobOfferEdit} create={JobOfferCreate} />
    <Resource name="posts" list={PostList} show={PostShow} edit={PostEdit} create={PostCreate} />
    <Resource name="authors" list={AuthorList}  show={AuthorShow} edit={AuthorEdit} create={AuthorCreate} />
  </Admin>
)

export default App;
