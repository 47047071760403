import * as React from "react";
import { List, SimpleList, Datagrid, TextField, DateField, BooleanField, ShowButton } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

export const PostList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => record.published}
                    linkType="show"
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="title" />
                    <TextField source="description" />
                    <TextField source="slug" />
                    <BooleanField source="published" />
                    <DateField source="updated_at" />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    )
};
