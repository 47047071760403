import * as React from "react";
import { Create, SimpleForm, TextInput, ImageInput, ImageField, Toolbar, SaveButton, required } from 'react-admin';

const EmployerCreateToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const EmployerCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<EmployerCreateToolbar />}>
        <TextInput source="name" validate={required()} />
        <TextInput source="city" validate={required()} />
        <TextInput source="street" validate={required()} />
        <TextInput source="street_number" validate={required()} />
        <TextInput source="website_url" type="url" validate={required()} />
        <ImageInput source="logo" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>
  </Create>
);
