import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { TopToolbar, ListButton, EditButton } from 'react-admin';

const AuthorShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
);

export const AuthorShow = props => (
    <Show actions={<AuthorShowActions/>} {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
        </SimpleShowLayout>
    </Show>
);
