import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import { TopToolbar, ListButton } from 'react-admin';

const RecruiterShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const RecruiterShow = props => (
    <Show actions={<RecruiterShowActions/>} {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="email" />
          <TextField source="sign_in_count" />
          <DateField source="last_sign_in_at" showTime />
          <DateField source="onboarded_at" showTime />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
        </SimpleShowLayout>
    </Show>
);
