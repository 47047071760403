import * as React from "react";
import { List, SimpleList, Datagrid, TextField, DateField, ShowButton } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

export const RecruiterList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.email}
                    secondaryText={record => new Date(record.onboarded_at).toLocaleDateString()}
                    tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
                    linkType="show"
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="email" />
                    <DateField source="onboarded_at" />
                    <DateField source="created_at" />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    )
};
