import * as React from "react";
import { List, SimpleList, Datagrid, TextField, DateField, ShowButton } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { TopToolbar, CreateButton } from 'react-admin';
import { ExportButton } from "./exportButton";


const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

export const EmployerList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} actions={<ListActions/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.city}
                    tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
                    linkType="show"
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="city" />
                    <TextField source="street" />
                    <TextField source="street_number" />
                    <DateField source="created_at" />
                    <DateField source="updated_at" />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    )
};
