import * as React from "react";
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, required } from 'react-admin';
import { NullableBooleanInput, AutocompleteInput, ReferenceInput, DateInput, NumberInput } from 'react-admin';
import MarkdownInput from 'ra-input-markdown';
import { FormDataConsumer, ArrayInput, SimpleFormIterator } from 'react-admin';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRecordContext } from 'react-admin';
import { useState, useRef, useMemo, useCallback } from "react"
import {useForm} from 'react-final-form';

const JobOfferEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

function DraggableMarker() {
  const record = useRecordContext();
  const form = useForm();
  const marker_position = {
    lat: record.latitude,
    lng: record.longitude,
  }

  const [draggable, setDraggable] = useState(false)
  const [position, setPosition] = useState(marker_position)
  const markerRef = useRef(null)

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          let position = marker.getLatLng();
          setPosition(marker.getLatLng());
          form.change('longitude', position.lng);
          form.change('latitude', position.lat);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'}
        </span>
      </Popup>
    </Marker>
  )
}

const MapField = ({ source }) => {
  const record = useRecordContext();
  const poland = [51.9194, 19.1451]
  if (!record.latitude && !record.longitude) return null;
  return (
    <>
      <MapContainer center={poland} zoom={6} scrollWheelZoom={false} >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        <DraggableMarker />
      </MapContainer>
    </>
  );
};

export const JobOfferEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<JobOfferEditToolbar />}>
          <TextInput disabled label="Id" source="id" />
          <TextInput source="title" validate={required()} />
          <ReferenceInput reference="employers" perPage={30} source="employer_id" >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(value) => value.trim().length >= 3}/>
          </ReferenceInput>
          <AutocompleteInput source="type" validate={required()}  choices={[
              { id: 'nurse', name: 'Nurse' },
              { id: 'doctor', name: 'Doctor' },
              { id: 'dentist', name: 'Dentist' },
              { id: 'midwife', name: 'Midwife' },
              { id: 'physiotherapist', name: 'Physiotherapist' },
              { id: 'caretaker', name: 'Caretaker' },
              { id: 'paramedic', name: 'Paramedic' },
              { id: 'other', name: 'Other' },
          ]} />
          <AutocompleteInput source="application_type" label="APPLICATION TYPE" validate={required()}  choices={[
              { id: 'redirect', name: 'URL - REDIRECT' },
              { id: 'contact_note', name: 'CONTACT NOTE' },
          ]} />
          <AutocompleteInput source="experience_level" validate={required()} choices={[
            { id: 'intern', name: 'Intern' },
            { id: 'regular', name: 'Regular' },
            { id: 'specialist', name: 'Specialist' },
          ]} />
          <FormDataConsumer>
              {({ formData, ...rest }) => (formData.application_type !== undefined && formData.application_type === "redirect")&&
                  <TextInput source="application_link" type="url" />
              }
          </FormDataConsumer>
          <FormDataConsumer>
              {({ formData, ...rest }) => (formData.application_type !== undefined && formData.application_type === "contact_note")&&
                  <TextInput source="application_contact" label="Contact Note"/>
              }
          </FormDataConsumer>
          <AutocompleteInput source="region" label="Region"  choices={[
              { id: 'ds', name: 'DOLNOSLASKIE' },
              { id: 'lu', name: 'LUBUSKIE' },
              { id: 'mz', name: 'MAZOWIECKIE' },
              { id: 'pol', name: 'PODLASKIE' },
              { id: 'sk', name: 'SWIETOKRZYSKIE' },
              { id: 'zp', name: 'ZACHODNIOPOMORSKIE' },
              { id: 'kp', name: 'KUJAWSKO-POMORSKIE' },
              { id: 'ld', name: 'LODZKIE' },
              { id: 'op', name: 'OPOLSKIE' },
              { id: 'pm', name: 'POMORSKIE' },
              { id: 'wm', name: 'WARMINSKO_MAZURSKIE' },
              { id: 'lb', name: 'LUBELSKIE' },
              { id: 'mp', name: 'MALOPOLSKIE' },
              { id: 'pk', name: 'PODKARPACKIE' },
              { id: 'sl', name: 'SLASKIE' },
              { id: 'wp', name: 'WIELKOPOLSKIE' },
          ]} />
          <AutocompleteInput source="country_code" label="CodeOfCountry" validate={required()}  choices={[
              { id: 'pl', name: 'Poland' },
              { id: 'de', name: 'Germany' },
              { id: 'gb', name: 'England' },
              { id: 'ie', name: 'Ireland' },
              { id: 'dk', name: 'Denmark' },
              { id: 'us', name: 'USA' },
          ]} />
          <TextInput source="city" validate={required()} />
          <TextInput source="street" validate={required()} />
          <TextInput source="street_number" validate={required()} />
          <NullableBooleanInput label="ASAP" source="asap" validate={required()}/>
          <FormDataConsumer>
              {({ formData, ...rest }) => (!formData.asap && formData.asap !== undefined)&&
                  <DateInput source="start_date" options={{ format: 'DD/MM/YYYY' }}/>
              }
          </FormDataConsumer>
          <NullableBooleanInput label="FULLTIME" source="full_time" validate={required()}/>
          <NullableBooleanInput label="REMOTE" source="remote" validate={required()}/>
          <ArrayInput source="employment_types">
            <SimpleFormIterator>
              <AutocompleteInput label="Type" source="type" validate={required()} choices={[
                    { id: 'permanent', name: 'Permanent' },
                    { id: 'b2b', name: 'B2B' },
              ]} />
              <AutocompleteInput label="Currency" source="salary.currency" validate={required()} choices={[
                    { id: 'pln', name: 'PLN' },
                    { id: 'eur', name: 'EUR' },
                    { id: 'gbp', name: 'GBP' },
              ]} />
              <AutocompleteInput label="Rate" source="rate" validate={required()} choices={[
                  { id: 'yearly', name: 'Yearly' },
                  { id: 'monthly', name: 'Monthly' },
                  { id: 'hourly', name: 'Hourly' },
                  { id: 'daily', name: 'Daily' },
              ]} />
              <NumberInput label="Min salary" validate={required()} source="salary.min" />
              <NumberInput label="Max salary" validate={required()} source="salary.max" />
            </SimpleFormIterator>
          </ArrayInput>
          <MarkdownInput source="description" validate={required()} />
            <MapField addLabel="true" source="map" fullWidth={true} />
            <TextInput source="longitude" />
            <TextInput source="latitude" />
        </SimpleForm>
    </Edit>
  );
};
