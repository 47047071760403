import axios from "axios";
import { API_URL } from "./env";

const httpClient = axios.create({
  baseURL: `${API_URL}/api/v1/admin`,
});

httpClient.interceptors.request.use((config) => {
  const rawAuth = localStorage.getItem("auth");
  const token = rawAuth ? JSON.parse(rawAuth)?.data?.auth_token : undefined;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export { httpClient };
