import * as React from "react";
import { useState } from 'react';
import { useDataProvider, useNotify, Button } from 'react-admin';

export const ExportButton = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const exportCsv = () => {
      setLoading(true);
      dataProvider
        .export('employers')
        .then(response => {
            setLoading(false);
            notify('Downloaded');
        })
        .catch(error => {
            setLoading(false);
            notify('Something went wrong!');
        });
      }
    return <Button label="Export to csv" onClick={exportCsv} disabled={loading} />;
};
