import * as React from "react";
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, required } from 'react-admin';

const AuthorEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const AuthorEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<AuthorEditToolbar />}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
