import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, BooleanField, ImageField, EditButton, TopToolbar } from 'react-admin';
import { useRecordContext } from 'react-admin';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { PublishButton } from "./publishButton"
import { UnpublishButton } from "./unpublishButton"

const JobOfferShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
       <EditButton basePath={basePath} record={data}/>
       <PublishButton record={data} />
       <UnpublishButton record={data} />
  </TopToolbar>
);

const MarkdownDescriptionField = ({ source, field }) => {
  const record = useRecordContext();
  return (
      <MarkdownPreview source={record[field]}/>
  );
};

export const PostShow = (props) => (
    <Show actions={<JobOfferShowActions/>} {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="meta_title" />
            <TextField source="meta_description" />
            <TextField source="author_name" />
            <MarkdownDescriptionField addLabel="true" field="description" source="description" />
            <MarkdownDescriptionField addLabel="true" field="body" source="body" />
            <BooleanField source="published" />
            <ImageField source="cover_url" title="cover" label="cover" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);
