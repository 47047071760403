import { httpClient } from "./../config/httpClient";
import { apiRoutes } from "./index";

export const authProvider = {
  login: (values) => {
    const params = { email: values.username, password: values.password };
    return httpClient
      .post(apiRoutes.login, params)
      .then(({ data }) => {
        window.localStorage.setItem("auth", JSON.stringify(data));
      })
      .catch(() => Promise.reject());
  },
  logout: async () => {
    try {
      await httpClient.delete(apiRoutes.login);
    } finally {
      window.localStorage.removeItem("auth");
      return Promise.resolve();
    }
  },
  checkError: (error) => {
    const status = error?.response?.status;
    if (status === 401 || status === 403) {
      window.localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (window.localStorage.getItem("auth") ? Promise.resolve() : Promise.reject()),
  getPermissions: () => Promise.resolve(),
};
