import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { SimpleList, ShowButton, TextInput, List, Datagrid, TextField, ChipField } from 'react-admin';

const postFilters = [
    <TextInput label="Title" source="q" alwaysOn />
];


export const JobOfferList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={postFilters} {...props}>
            {isSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.city}
                        tertiaryText={record => record.region}
                        linkType="show"
                    />
                ) : (
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="title" />
                        <TextField source="city" />
                        <TextField source="company_name" />
                        <TextField source="street" />
                        <ChipField source="status" />
                        <ShowButton />
                    </Datagrid>
                )}
        </List>
    );
};
