import * as React from "react";
import { useState } from 'react';
import { useDataProvider, useNotify, useRefresh, Button, Confirm } from 'react-admin';

export const BumpButton = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const myrefresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  if (!record) return null;
  if (record.status !== "active") {
    return <Button label="Bump" disabled={true} />;
  }
  const bump = () => {
    setLoading(true);
    dataProvider
      .bump('job_offers', { id: record.id })
      .then(response => {
          setLoading(false);
          myrefresh();
          notify('Offer bumped');
      })
      .catch(error => {
          setLoading(false);
          notify('Something went wrong!');
      });
    }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    bump();
    setOpen(false);
  }

  return (
    <>
        <Button label="Bump" onClick={handleClick} />
        <Confirm
            isOpen={open}
            loading={loading}
            title={`Bumping #${record && record.id}`}
            content="Are you sure you want to bump this offer?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>
    );
};
