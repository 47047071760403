import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { SimpleList, ShowButton, Filter, ReferenceInput, SelectInput, List, Datagrid, TextField, ChipField } from 'react-admin';


const OfferFilter = (props) => (
  <Filter {...props}>
      <ReferenceInput label="Employer" source="employerId" reference="employers" allowEmpty alwaysOn>
          <SelectInput optionText="name" />
      </ReferenceInput>
  </Filter>
);

export const JobOfferToCheckList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<OfferFilter />} {...props}>
            {isSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.city}
                        tertiaryText={record => record.region}
                        linkType="show"
                    />
                ) : (
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="title" />
                        <TextField source="city" />
                        <TextField source="street" />
                        <ChipField source="status" />
                        <ShowButton />
                    </Datagrid>
                )}
        </List>
    );
};
