import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, ImageField, UrlField } from 'react-admin';
import { TopToolbar, ListButton, EditButton } from 'react-admin';

const EmployerShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
);

export const EmployerShow = props => (
    <Show actions={<EmployerShowActions/>} {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <ImageField source="logo_url" title="logo" label="Logo" />
-         <TextField source="city" />
-         <TextField source="street" />
-         <TextField source="street_number" />
          <UrlField  source="website_url" />
-         <DateField source="created_at" showTime />
-         <DateField source="updated_at" showTime />
        </SimpleShowLayout>
    </Show>
);
