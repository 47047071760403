import * as React from "react";
import { useState } from 'react';
import { useDataProvider, useNotify, useRefresh, Button } from 'react-admin';

export const RejectButton = ({ record }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const myrefresh = useRefresh();
    const [loading, setLoading] = useState(false);
    if (!record) return null;
    if (record.status === "active") {
      return <Button label="Reject" disabled={true} />;
    }
    const reject = () => {
      setLoading(true);
      dataProvider
        .reject('job_offers_to_check', { id: record.id })
        .then(response => {
            setLoading(false);
            myrefresh();
            notify('Offer rejected');
        })
        .catch(error => {
            setLoading(false);
            notify('Something went wrong!');
        });
      }
    return <Button label="Reject" onClick={reject} disabled={loading} />;
};
