import * as React from "react";
import { Edit, SimpleForm, TextInput, ImageInput, SaveButton, Toolbar, ImageField, required } from 'react-admin';

const EmployerEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const EmployerEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<EmployerEditToolbar />}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={required()} />
        <TextInput source="city" validate={required()} />
        <TextInput source="street" validate={required()} />
        <TextInput source="street_number" validate={required()} />
        <TextInput source="website_url" type="url" validate={required()} />
        <ImageInput source="logo" accept="image/*" placeholder={"Upload only if you want to change current one"}>
          <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>
  </Edit>
);
