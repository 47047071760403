import * as React from "react";
import { Create, SimpleForm, TextInput, Toolbar, SaveButton, required } from 'react-admin';

const AuthorCreateToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const AuthorCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<AuthorCreateToolbar />}>
        <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
