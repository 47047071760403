import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, BooleanField, ChipField, UrlField, EditButton, TopToolbar, ReferenceField } from 'react-admin';
import { useRecordContext, ArrayField, Datagrid } from 'react-admin';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { PublishButton } from "./publishButton"
import { UnpublishButton } from "./unpublishButton"
import { BumpButton } from "./bumpButton";

const MarkdownDescriptionField = ({ source }) => {
  const record = useRecordContext();
  return (
    <MarkdownPreview source={record.description}/>
  );
};

const MapField = ({ source }) => {
  const record = useRecordContext();
  const poland = [51.9194, 19.1451]
  if (!record.latitude && !record.longitude) return null;
  return (
    <>
      <MapContainer center={poland} zoom={6} scrollWheelZoom={false} >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        <Marker position={[record.latitude, record.longitude]}>
        </Marker>
      </MapContainer>
    </>
  );
};

const JobOfferShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
       <PublishButton record={data} />
       <UnpublishButton record={data} />
       <BumpButton record={data} />
       <EditButton basePath={basePath} record={data}/>
  </TopToolbar>
);


export const JobOfferShow = (props) => (
    <Show actions={<JobOfferShowActions/>} {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="employer_name" />
            <TextField source="company_name" />
            <TextField source="recruiter_email" />
            <ReferenceField source="recruiter_id" reference="recruiters" link="show">
                <TextField source="email"/>
            </ReferenceField>
            <ChipField source="type" />
            <ChipField source="experience_level" />
            <ChipField source="status" />
            <ArrayField source="employment_types">
              <Datagrid>
                  <ChipField source="rate" />
                  <ChipField source="type" />
                  <ChipField label="Min salary" source="salary.min" />
                  <ChipField label="Max salary" source="salary.max" />
                  <ChipField label="Salary Currency" source="salary.currency" />
              </Datagrid>
            </ArrayField>
            <MarkdownDescriptionField addLabel="true" source="description" />
            <ChipField source="application_type" />
            <UrlField source="application_link" />
            <TextField source="application_contact" />
            <TextField source="country_code" />
            <TextField source="region" />
            <TextField source="city" />
            <TextField source="street" />
            <TextField source="street_number" />
            <MapField addLabel="true" source="map" fullWidth={true} />
            <TextField source="longitude" />
            <TextField source="latitude" />
            <BooleanField source="asap" />
            <DateField source="start_date" />
            <BooleanField source="full_time" />
            <BooleanField source="remote" />
            <TextField source="views_counter" />
            <DateField source="first_publication_at" showTime />
            <DateField source="published_at" showTime />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
        </SimpleShowLayout>
    </Show>
);
