import * as React from "react";
import { Create, SimpleForm, TextInput, NullableBooleanInput, AutocompleteInput, ReferenceInput, Toolbar, SaveButton, DateInput, NumberInput, required } from 'react-admin';
import MarkdownInput from 'ra-input-markdown';
import { FormDataConsumer } from 'react-admin';

const JobOfferCreateToolbar = props => (
  <Toolbar {...props} >
      <SaveButton redirect="show" />
  </Toolbar>
);

export const JobOfferCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<JobOfferCreateToolbar />}>
        <TextInput source="title" validate={required()} />
        <ReferenceInput reference="employers" perPage={30} validate={required()} source="employer_id" >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(value) => value.trim().length >= 3}/>
        </ReferenceInput>
        <AutocompleteInput source="type" validate={required()}  choices={[
            { id: 'nurse', name: 'Nurse' },
            { id: 'doctor', name: 'Doctor' },
            { id: 'dentist', name: 'Dentist' },
            { id: 'midwife', name: 'Midwife' },
            { id: 'physiotherapist', name: 'Physiotherapist' },
            { id: 'caretaker', name: 'Caretaker' },
            { id: 'paramedic', name: 'Paramedic' },
            { id: 'other', name: 'Other' },
        ]} />
        <AutocompleteInput source="experience_level" validate={required()} choices={[
            { id: 'intern', name: 'Intern' },
            { id: 'regular', name: 'Regular' },
            { id: 'specialist', name: 'Specialist' },
        ]} />
        <AutocompleteInput source="application_type" label="APPLICATION TYPE" validate={required()}  choices={[
            { id: 'redirect', name: 'URL - REDIRECT' },
            { id: 'contact_note', name: 'CONTACT NOTE' },
        ]} />
        <FormDataConsumer>
            {({ formData, ...rest }) => (formData.application_type !== undefined && formData.application_type === "redirect")&&
                <TextInput source="application_link" type="url" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => (formData.application_type !== undefined && formData.application_type === "contact_note")&&
                <TextInput source="application_contact" label="Contact Note"/>
            }
        </FormDataConsumer>
        <AutocompleteInput source="region" label="Region" choices={[
            { id: 'ds', name: 'DOLNOSLASKIE' },
            { id: 'lu', name: 'LUBUSKIE' },
            { id: 'mz', name: 'MAZOWIECKIE' },
            { id: 'pol', name: 'PODLASKIE' },
            { id: 'sk', name: 'SWIETOKRZYSKIE' },
            { id: 'zp', name: 'ZACHODNIOPOMORSKIE' },
            { id: 'kp', name: 'KUJAWSKO-POMORSKIE' },
            { id: 'ld', name: 'LODZKIE' },
            { id: 'op', name: 'OPOLSKIE' },
            { id: 'pm', name: 'POMORSKIE' },
            { id: 'wm', name: 'WARMINSKO_MAZURSKIE' },
            { id: 'lb', name: 'LUBELSKIE' },
            { id: 'mp', name: 'MALOPOLSKIE' },
            { id: 'pk', name: 'PODKARPACKIE' },
            { id: 'sl', name: 'SLASKIE' },
            { id: 'wp', name: 'WIELKOPOLSKIE' },
        ]} />
        <AutocompleteInput source="country_code" label="CodeOfCountry" validate={required()}  choices={[
            { id: 'pl', name: 'Poland' },
            { id: 'de', name: 'Germany' },
            { id: 'gb', name: 'England' },
            { id: 'ie', name: 'Ireland' },
            { id: 'dk', name: 'Denmark' },
            { id: 'us', name: 'USA' },
        ]} />
        <TextInput source="city" validate={required()} />
        <TextInput source="street" validate={required()} />
        <TextInput source="street_number" validate={required()} />
        <NullableBooleanInput label="ASAP" source="asap" validate={required()}/>
        <FormDataConsumer>
            {({ formData, ...rest }) => (!formData.asap && formData.asap !== undefined)&&
                <DateInput source="start_date" options={{ format: 'DD/MM/YYYY' }}/>
            }
        </FormDataConsumer>
        <NullableBooleanInput label="FULLTIME" source="full_time" validate={required()}/>
        <NullableBooleanInput label="REMOTE" source="remote" validate={required()}/>
        <AutocompleteInput source="b2b_rate" label="Rate for b2b" choices={[
            { id: 'yearly', name: 'Yearly' },
            { id: 'monthly', name: 'Monthly' },
            { id: 'hourly', name: 'Hourly' },
            { id: 'daily', name: 'Daily' },
        ]} />
        <NumberInput label="B2B min salary" source="b2b_min" />
        <NumberInput label="B2B max salary" source="b2b_max" />
        <AutocompleteInput source="perm_rate" label="Rate for permanent" choices={[
            { id: 'monthly', name: 'Monthly' },
            { id: 'hourly', name: 'Hourly' },
            { id: 'daily', name: 'Daily' },
        ]} />
        <NumberInput label="PERMANENT min salary" source="perm_min" />
        <NumberInput label="PERMANENT max salary" source="perm_max" />
        <AutocompleteInput source="currency" choices={[
              { id: 'pln', name: 'PLN' },
              { id: 'eur', name: 'EUR' },
              { id: 'gbp', name: 'GBP' },
        ]} />
        <MarkdownInput source="description" validate={required()} />
    </SimpleForm>
  </Create>
);
